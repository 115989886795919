<template>

  <div class="page" style="max-width: 600px; margin: 0 auto;">
    敬请期待
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  mounted() {


  },
  methods: {
    goNext() {

    }

  }
}
</script>

<style scoped>

</style>
